import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { connect } from 'react-redux'
import { setCart } from "../state/app"
import CloseIcon from '../images/header-footer/close.svg'
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Fab } from "@material-ui/core";
import Button2 from "./Button"
import { Link, graphql } from "gatsby"
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Offcanvas } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import "../components/style/ProductBanner.css"


const Banner = ({ data, dispatch, cart, title, subtitle }) => {
    const [addedToCart, setAddedToCart] = useState(false)
    const [showCartMenu, setShowCartMenu] = useState(false)
    const [updateCart, setUpdateCart] = useState(cart);
    const { t } = useTranslation()


    useEffect(() => {
        dispatch(setCart(updateCart))
    }, [updateCart])




    const image = {
        image: getImage(data.image?.localFile),
        alt: data.image?.altText || '',
    }


    return (
        <>
            <Offcanvas placement="end" show={showCartMenu} >

                <Offcanvas.Body>
                    <CartMenuStyled>
                        <Offcanvas.Header>
                            <div>
                                {cart.length >= 1 &&
                                    <div className="newlayout">
                                        <div className="campo">
                                            <h4 className="text">{title.button.requests.title}</h4>
                                            <div className="icone">
                                                <h6 className="close-text">{title.button.close.title}</h6>
                                                <CloseIcon
                                                    className="close-cart"
                                                    onClick={() => setShowCartMenu(!showCartMenu)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Offcanvas.Header>

                        {cart.length === 0 &&
                            <div className="cartLength">
                                <div className="cart-btn">
                                    <CloseIcon
                                        className="close-cart"
                                        onClick={() => setShowCartMenu(!showCartMenu)}
                                    />
                                </div>

                                <h4>
                                    {subtitle.header.menu.budget.text}
                                    <br></br>
                                    {subtitle.header.menu.budget.text2}
                                </h4>

                                <div className="cart-secBtn">
                                    <Button2
                                        text={subtitle.header.menu.budget.option1}
                                        to={"/products"}
                                    />
                                    <Button2
                                        text={subtitle.header.menu.budget.option2}
                                        to={"/services"}
                                    />
                                </div>
                            </div>
                        }

                        {/* CART COM PRODUTOS */}
                        {cart.map((product, i) => {
                            const image = {
                                image: getImage(product.image?.localFile),
                                alt: product.image?.altText || '',
                            }
                            return (
                                <div className='product' key={i}>
                                    {image?.image &&
                                        <GatsbyImage image={image.image} alt={image.alt || ''} className='product-image' />
                                    }
                                    <div className='product-info'>
                                        <h3>{product.name}</h3>
                                        <div className='product-quantity'>
                                            {title.button.quantity.title}
                                            <b>{product.quantity}</b>
                                            <br></br><br></br>

                                            <div className="product-btn">
                                                <div className="product-decrement">
                                                    <Fab size="small" onClick={() => {
                                                        cart.find(addedProduct => {
                                                            if (addedProduct.id === product.id) {
                                                                addedProduct.quantity = addedProduct.quantity - 1
                                                            }
                                                        })
                                                        setUpdateCart([...cart])

                                                        if (product.quantity <= 0) {
                                                            setUpdateCart(updateCart.filter(deleteProduct => deleteProduct.id !== product.id))
                                                        }
                                                    }}><RemoveIcon fontSize="small" /></Fab>
                                                </div>
                                                <div className="product-increment">
                                                    <Fab size="small" onClick={() => {
                                                        if (product.quantity >= 1) {
                                                            cart.find(addedProduct => {
                                                                if (addedProduct.id === product.id) {
                                                                    addedProduct.quantity = addedProduct.quantity + 1
                                                                }
                                                            })
                                                            setUpdateCart([...cart])
                                                        }
                                                    }}><AddIcon fontSize="small" /></Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CloseIcon
                                        className='remove-product'
                                        onClick={() => setUpdateCart(updateCart.filter(deleteProduct => deleteProduct.id !== product.id))}
                                    />
                                </div>
                            )
                        })}
                        {cart.length !== 0 &&
                            <div className='bottom-check'>
                                <button className="box" ><a href="/pedido">{title.button.send.title}</a></button>
                                <button className="box2" ><a href="/services">{title.button.consult.title}</a></button>
                                <button className="box3" ><a href="/products">{title.button.consultMach.title}</a></button>
                            </div>
                        }
                    </CartMenuStyled>
                </Offcanvas.Body>
            </Offcanvas>
            <BannerStyled>
                <div className="breeadcrumb">
                    <Breadcrumbs aria-label="breadcrumb"
                        style={{
                            fontFamily: "montserrat",
                            textTransform: "uppercase"
                        }}>

                        <Link
                            underline="hover"
                            color="inherit"
                            href="javascript: history.go(-1)"
                        >
                            voltar
                        </Link>
                        <Link
                            underline="hover"
                            aria-current="page"
                            style={{ color: "#F2AF00", fontWeight: "600" }}
                        >
                            {data.name}
                        </Link>
                    </Breadcrumbs>
                </div>
                <div className='wrapper'>
                    {image?.image &&
                        <div className='image-container'>
                            <GatsbyImage image={image.image} alt={image.alt || ''} className='image' />
                        </div>
                    }
                    <section className='content'>
                        <div>
                            {data.name &&
                                <div className='title'>
                                    <h2>{data.name}</h2>
                                </div>
                            }
                            {data.shortDescription &&
                                <div className='description' dangerouslySetInnerHTML={{ __html: data.shortDescription }}></div>
                            }
                        </div>
                        <div className="cont-all">
                            <div className="count-container">

                                <button
                                    className='add-to-cart'
                                    onClick={() => {
                                        {/**if (cart.filter(product => product.id === data.id).length > 0) {
                                            console.log(cart)
                                            setAddedToCart(true)
                                            setShowCartMenu(true)
                                            setUpdateCart(updateCart.filter(product => {
                                               if( product.id === data.id){
                                                    if(product.id.hasOwnProperty('quantity')){
                                                        product.id.quantity = product.id.quantity + 1
                                                    }else{
                                                        product.id.quantity = 1
                                                    }
                                               }
                                            }))

                                            Update(data.id, "add")

                                        } else {*/}

                                        if (data.hasOwnProperty('quantity')) {
                                            data.quantity = data.quantity + 1
                                        } else {
                                            data.quantity = 1
                                        }
                                        setAddedToCart(true)
                                        setUpdateCart([...cart, data])
                                        setShowCartMenu(true)

                                        {/**} */ }

                                    }}>
                                    {addedToCart ?
                                        title.button.added.title
                                        :
                                        title.button.add.title
                                    }

                                </button>

                            </div>
                        </div>
                    </section>
                </div>
            </BannerStyled>
        </>
    )
}

export default connect(state => ({
    cart: state.app.cart,
    products: state.app.products
}), null)(Banner)

export const query = graphql`
query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


const CartMenuStyled = styled.div`


.newlayout{
    .campo{
        display: grid;
        grid-template-columns: auto 1fr;
        border-bottom: 1px solid #000;
        gap: 41%;
        padding: 31px 5%;
        gap: 39%;
        padding-right: 6%;
        align-items: center;

        ${media.s`
            padding: 6.4% 5%;
        `}

        @media only screen and (max-width: 1580px){
            gap:40%;
            gap:38%;
        }
        .text{
            letter-spacing: 2px; 
            font-weight: 600;
            text-transform: uppercase;
            font-size: 8px;
        }
        .icone {
            display: flex;
            margin-top: 12px;
            gap: 2%;
            justify-content: space-between;
            
            .close-text{
                padding-right: 10px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .close-cart {
                padding: 9px;
                border-radius: 50%;
                background-color: #fff;
            }
        }
    }
}

.cartLength {
    .cart-btn {
        margin: 8px;

        .close-cart{
            width:25px;
            height:25px;
            cursor: pointer;
        }
    }

    h4{
        text-align: center;
        margin-top: 1rem;
        
    }

    .cart-secBtn {
        display: flex;
        gap: 1rem;
        text-align: center;
        justify-content: center;
    }

}
    
    .product-btn {
        display: flex;
    }

    .product-decrement, .product-increment {
        padding: 5px;
    }


    .product {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap:5%;
        padding: 5%;
        border-bottom: 1px solid ${color.gray};
        max-width: 360px

        .product-info {
            .product-quantity {
                
            }
        }

        .remove-product {
            width: 20%;
            margin-left: 80%;
        }
    }


    .bottom-check{
        padding: 1rem 5%;
        background-color:#ebebeb;
        position: sticky;
        bottom: 0;

        .box {
            width:100%;
            margin-bottom: 5%;
            padding: 1.5rem clamp(15px, 5vw, 35px) ;
            background-color: #F2AF00 ;
            border: none;

            a{
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2%;
                font-family: "Montserrat"
            }

            :hover {
                background-color: #000;
                color: #F2AF00;
            }
        }
        .box2 {
            width:100%;
            margin-bottom: 5%;
            padding: 1.5rem clamp(15px, 5vw, 35px) ;
            background-color: #8080806e ;
            border: none;

            a{
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2%;
                font-family: "Montserrat"
            }

            :hover {
                background-color: #000;
                color: #fff;
            }
        }
        .box3 {
            width:100%;
            margin-bottom: 5%;
            padding: 1.5rem clamp(15px,5vw,35px) ;
            background-color: #8080806e ;
            border: none;

            a{
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2%;
                font-family: "Montserrat";
            }

            :hover {
                background-color: #000;
                color: #fff;
            }
        }
    }


`

const BannerStyled = styled.div`
    .breeadcrumb {
        padding: clamp(34px,15vw,0px) 5%; 
    }

    position:relative;

    .wrapper{
        margin:0 auto;
        padding: clamp(0px,15vw,0px) 5%;
        color:${color.black};
        display:grid;
        grid-template-columns:repeat(2,1fr);
        gap:5%;

        ${media.l`
            grid-template-columns:1fr;
            gap:1rem;
        `}

        .image-container{
            position:relative;
            
            .image{
                width: 90%;
                
                ${media.l`
                    width: 100%;
                `}
            }
        }

        .content{
            display:flex;
            flex-direction:column;
            justify-content:center;
            gap:0rem;

            .title{
                h2{
                    font-weight: 700;
                    font-size: 4rem;
                    text-transform: uppercase;
                    line-height: inherit;

                    ${media.l`
                        font-size: 3rem;
                    `}

                    ${media.s`
                        font-size: 2.5rem;
                    `}
                }
            }

            .description{
                line-height:1.8rem;
                display: flex;
                flex-direction: column;
                gap: 3rem;

                ul{
                    margin:0;
                    padding-left:20px;

                }

                a{
                    text-decoration:none;
                } 
            } 

            a{
                display: block;
                width:fit-content;
                color: ${color.white};
                background: grey;
                border:0;
                padding: 1rem clamp(15px, 10vw, 83px);
                letter-spacing: 1px;
                text-align: center;
                position: relative;
                transition: all .35s;
                cursor:pointer;
                font-weight:600;
                text-transform:uppercase;
                margin-top:1rem;
                font-family: "montserrat";
                margin-top: 0px;
                
                ${media.s`
                    padding: 1rem clamp(15px,80px,20vw);
                `}

                :hover{
                    background: black;
                    transition: .5s
                }
            }

            .count{
                display: block;
                width:fit-content;
                text-decoration: none;
                color: ${color.white};
                background: ${color.yellow}; 
                border:0;
                padding: 0rem clamp(15px, 5vw, 35px);
                letter-spacing: 1px;
                text-align: center;
                position: relative;
                transition: all .35s;
                cursor:pointer;
                font-weight:600;
                margin-top:1rem;
                text-transform:uppercase;
                padding-top:17px;
                
            }
            .cont-all {
                display: grid;
                gap: 1rem;

                .count-container{
                    display:flex;

                    svg{
                        width:23px;
                        fill:#919191;
                    }
                    
                    .arrow{
                        border:1px solid ${color.yellow};
                        margin-top: 14px; 
                        margin-right: 12px;
                    }

                    .number{
                        padding-left:43%;
                        padding-right:50%;
                    }
                }

                .add-to-cart{
                    display: block;
                    width:fit-content;
                    text-decoration: none;
                    color: ${color.white};
                    background: ${color.yellow}; 
                    border:0;
                    padding: 1.25rem clamp(15px,8vw,30px);
                    letter-spacing: 1px;
                    text-align: center;
                    position: relative;
                    transition: all .35s;
                    cursor:pointer;
                    font-weight:600;
                    text-transform:uppercase;
                    margin-top:2rem;
                    font-family: "montserrat";
                    
                    :hover {
                        background-color: #000;
                        color: ${color.yellow};
                    }
                }
            }
        }
    }
`


