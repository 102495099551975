import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/ProductBanner"
import Description from "../components/ProductDescription"
import Gallery from "../components/ProductGallery"
import { useTranslation } from "react-i18next"
import { useI18next } from 'gatsby-plugin-react-i18next'
import { connect } from 'react-redux'


const checkLanguage = (el, language) => el.productTags.nodes.filter(function (el) {
  if (el.name === language) {
    return true
  } else {
    return false
  }
})

const ProductTemplate = ({ data, products, pageContext }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const description = data.product.description
  var link = data.product.link
  var productLanguage = data.product


  var isLanguage = false
  isLanguage = checkLanguage(pageContext, language)
  if (isLanguage.length <= 0) {
    if (pageContext.productsGroup.length > 0) {
      link = pageContext.productsGroup[0].node.products.nodes[0].link
      products.filter(function (el) {
        if (el.link == link) {
          productLanguage = el
          return
        }
      })
    }

  }


  const gallery = productLanguage.galleryImages.nodes.length // TAMANHO DA GALERIA DE IMAGENS (0 = NÃO TEM IMAGENS NA GALERIA | + de 0 = TEM IMAGENS NA GALERIA)

  return (
    <Layout data={t("home", { returnObjects: true })}>

      <Seo title={productLanguage.name} description={productLanguage.description} />
      <Banner data={productLanguage} title={t("products", { returnObjects: true })} subtitle={t("home", { returnObjects: true })} />
      {description !== null && ( // SE O PRODUTO TIVER DESCRIÇÃO
        <Description data={productLanguage.description} title={t("products", { returnObjects: true })} />
      )}
      {gallery !== 0 && ( // SE O PRODUTO TIVER GALERIA DE IMAGENS
        <Gallery data={productLanguage.galleryImages.nodes} title={t("products", { returnObjects: true })} />
      )}
      <div style={{ padding: "5vw" }}></div>
    </Layout>
  )
}

export default connect(state => ({
  products: state.app.products,
  productType: state.app.productType,
}), null)(ProductTemplate)


export const pageQuery = graphql`
  query productTemplate($id: String!, $language: String!) {
    # selecting the current product by id
    product: wpProduct(id: { eq: $id }) {
      name
      description
      shortDescription
      id
      productCategories {
        nodes {
          slug
          name
        }
      }
      link
      galleryImages {
        nodes {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, JPG]
                quality: 100
              )
            }
          }
          altText
        }
      }
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              formats: [AUTO, WEBP, JPG]
              quality: 100
            )
          }
        }
      }
    }

    allWpProductTag {
      nodes {
        name
      }
    }

    

    locales: allLocale(filter: {ns: {in: ["about", "home", "products"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  
`
