import * as React from "react"
import styled from 'styled-components'
import { color } from './Styles'
import Title from "./Title"

const Description = ({ data, title }) => {

    return (
        <DescriptionStyled>
            <Title 
                title={title.page.tech.title} 
            />
            <section className='content'>
                <div className='description' dangerouslySetInnerHTML={{ __html: data }}></div>
            </section>
        </DescriptionStyled>
    )
}

export default Description

const DescriptionStyled = styled.div`
    position:relative;

    .Title__TitleStyled-Jfvli.kccrFE {
        margin: 4rem auto 3rem auto;
    }

    .fill {
        padding-left: 5%;

        h2 {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 1.5rem;
            letter-spacing: 0.5rem;
            border-bottom: 2px solid ${color.yellow};
            max-width: 50%;
        }
    }
    
    .content{
        margin: 0 auto;
        padding: clamp(0px,0vw,0px) 10%;
        color: ${color.black};

        .description{
            line-height:1.8rem;

            ul{
                margin:0;
                padding-left:20px;
            }

            a{
                display: block;
                width:fit-content;
                text-decoration: none;
                color: ${color.white};
                background: ${color.yellow};
                padding: 1rem clamp(15px, 5vw, 35px);
                letter-spacing: 1px;
                text-align: center;
                position: relative;
                transition: all .35s;
                cursor:pointer;
                font-weight:600;
                text-transform:uppercase;
                margin-top:1rem;
                
                :hover{
                    box-shadow: 6px 6px 13px -6px rgb(27 28 40 / 80%);
                }
            } 
        } 
    }
`