import React, { useState } from "react"
import styled from "styled-components"
import { color, media } from "./Styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import CloseIcon from '../images/header-footer/close.svg'
import Title from "./Title"

const Gallery = ({ data, title }) => {
    const [open, setOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    return (
        <GalleryStyled>
            <div className="wrapper">
                <Title
                    title={title.page.example.title} 
                />
                <div className='images'>
                    {data.map((image, key) => (
                        <div
                            className='image-container'
                            key={key}
                            onClick={() => { setOpen(true); setSelectedImage(key) }}
                        >
                            <GatsbyImage
                                image={getImage(image?.localFile)}
                                alt={image.altText || ''}
                                className="image"
                            />
                        </div>
                    ))}
                </div>
                {open &&
                    data.map((image, key) => (
                        key === selectedImage &&
                        <div className='lightbox' key={key}>
                                <span
                                    className='close'
                                    onClick={() => { setOpen(false); setSelectedImage(null) }}
                                >
                                    <CloseIcon />
                                </span>
                                <GatsbyImage
                                    image={getImage(image?.localFile)}
                                    alt={image.altText || ''}
                                    className="image"
                                />
                            </div>
                    ))
                }
            </div>
        </GalleryStyled>
    )
}

export default Gallery

const GalleryStyled = styled.div`
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        color:${color.black};

        .images{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap:1rem;

            ${media.l`
                grid-template-columns: repeat(2,1fr);
            `}

            .image-container{
                height:300px;
                cursor:pointer;

                ${media.s`
                    height:200px;
                `}

                .image{
                    height:100%;
                }
            }
        }

        .lightbox{
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index:1;
            padding: 5%;
            box-sizing: border-box;
            background-color: #000000CC;

            .close{
                position:absolute;
                right:5%;
                top:5%;
                z-index:1;
                cursor:pointer;

                svg{
                    width: 30px;
                    height: 30px;

                    ${media.m`
                        width:20px;
                        height:20px;
                    `}
                }
            }

            .image{
                height:100%;
                width:100%;
                pointer-events:none;

                img{
                    object-fit: contain !important;
                }
            }
        }
    }
`